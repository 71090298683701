export enum AnalyticsEvent {
  PAGE_VIEW = "PAGE_VIEW",

  JOB_POST_IMPRESSION = "JOB_POST_IMPRESSION",
  JOB_POST_DETAIL = "JOB_POST_DETAIL",
  JOB_POST_APPLY = "JOB_POST_APPLY",

  CHECKOUT_CREATE = "CHECKOUT_CREATE",

  SEARCH_JOB_POSTS = "SEARCH_JOB_POSTS",
}
