import mixpanel from "mixpanel-browser";
import { JsonObject } from "type-fest";

mixpanel.init(process.env["NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN"], {
  debug: true,
  //   ignore_dnt: true,
});

type MixpanelEvent = {
  name: string;
  payload: JsonObject;
};

export const mixpanelEvent = ({ name, payload }: MixpanelEvent) => {
  try {
    mixpanel.track(name, payload);
  } catch (e) {
    console.log(e);
  }
};
